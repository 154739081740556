import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, IAction, ModuleKeywords, PolicyBodySection, PolicyBodySectionTemplateDto, getEnumOptions } from '@shared/classes';
import { BaseFormPopupAltComponent } from '@shared/components/misc/base-form-popup-alt/base-form-popup-alt.component';
import { AppDialogService, ViewModeService } from '@shared/services';
import { PolicyBodySectionTemplateDataService } from 'app/modules/org-framework/service/data/policy-body-section-template-data.service';
import { PolicySectionTemplateSelectorComponent } from '../policy-section-template-selector/policy-section-template-selector.component';

@Component({
  selector: 'app-policy-body-section-template-item-form',
  templateUrl: './policy-body-section-template-item-form.component.html',
  styleUrl: './policy-body-section-template-item-form.component.scss',
})
export class PolicyBodySectionTemplateItemFormComponent extends BaseForm<PolicyBodySectionTemplateDto> implements OnInit {
  @Input() set showTemplateButton(value) {
    this.showFromTemplateButton = value;
  }
  constructor(public viewModeService: ViewModeService, private requestService: PolicyBodySectionTemplateDataService
    , private appDialogService: AppDialogService,
  ) {
    super(viewModeService, ModuleKeywords.PolicyBodySectionTemplate);
  }
  policySectionTypes = getEnumOptions(PolicyBodySection.TypeEnum);
  policyBodySectionTemplateControl = new FormControl(null);
  showFromTemplateButton: boolean = false;
  fromTemplateButtonAction: IAction = {
    id: 1,
    label: 'Add From Template',
    buttonType: 'button',
    command: this.onOpenEditDialog.bind(this),
    icon: 'pi pi-external-link',
    loading$: this.loadingState$,
    buttonStyle: 'outlined',
  };
  ngOnInit(): void { }

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.formGroup.patchValue({ ...data });
    this.data = data;
    if (data?.showTemplateButton) {
      this.showFromTemplateButton = true;
    }
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      subject: new FormControl(null, Validators.required),
      body: new FormControl(null),
      parent: new FormControl(null),
      type: new FormControl(null, Validators.required),
    });
  }
  onOpenEditDialog() {
    this.appDialogService.showDialog(
      BaseFormPopupAltComponent,
      'Policy Body Section Template',
      (res) => {
        if (res) {
          let resObj = {};
          let itemsList = (res?.createItems) ?? res ?? [];
          itemsList.forEach((element) => {
            resObj[element.key] = element.value;
          });
          this.formSubmitSave.emit(resObj);
        }
      },
      {
        data: {
          dynamicViewComponent: PolicySectionTemplateSelectorComponent,
          dataService: this.requestService,
          filters: [],
          selectedRows: [],
          patchData: false,
          formData: this.formGroup.getRawValue(),
        },
      }
    );
  }

}
