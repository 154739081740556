<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <!-- <div class="col-12">
      <app-target-code-selector
        [label]="moduleFieldString + '.policyBodySectionTemplate.label' | translate: 'Policy Body Section Template'"
        [placeholder]="
          moduleFieldString + '.policyBodySectionTemplate.placeholder' | translate: 'Enter Policy Body Section Template'
        "
        [control]="policyBodySectionTemplateControl"
        [viewMode]="'create'"
        [multi]="false"
        [optionLabel]="'name'"
        [optionValue]="undefined"
        [dataKey]="'code'"
        [customProjectionFields]="['code', 'subject', 'type', 'body']"
        [targetTypes]="['POLICY_BODY_SECTION_TEMPLATE']"
      >
      </app-target-code-selector>
    </div> -->
    <div class="col-12 md:col-6">
      <app-basic-input
        label="Title"
        [placeholder]="'Enter Title'"
        [control]="formGroup?.controls?.subject"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-drop-down-input
        [label]="'Type'"
        [placeholder]="'Select Item'"
        [name]="'type'"
        [optionLabel]="'label'"
        [multi]="false"
        [items]="policySectionTypes"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.type"
        [badgeView]="true"
        [viewMode]="fieldViewMode"
      ></app-drop-down-input>
    </div>

    <div class="col-12 md:col-12">
      <app-text-editor
        [height]="'100px'"
        [advanced]="true"
        [name]="'body'"
        label="Section Body"
        [placeholder]="'Enter Text...'"
        [control]="formGroup?.controls?.body"
        [viewMode]="fieldViewMode"
      ></app-text-editor>
    </div>
  </div>

  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton && showSaveAndClose) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create' && showSaveAndClose) {
        <app-button [action]="cancelButtonAction"></app-button>
      }
      @if (showFromTemplateButton) {
        <app-button [action]="fromTemplateButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create' && showSaveAndClose) {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
